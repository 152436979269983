import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { DealerFeature, EventTrackingService, LoginFeature, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { ElementType, TrackingType } from '@hyundai/shared-lib';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-nav-bar',
    standalone: true,
    imports: [RouterLinkDirective, NgClass],
    templateUrl: './nav-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NavBarComponent {
    TrackingType = TrackingType;
    ElementType = ElementType;
    tracking = inject(EventTrackingService);
    store = inject(Store);

    dealer = this.store.selectSignal(DealerFeature.state.selectLoadedDealer);
    dealerServiceMailLink = this.store.selectSignal(DealerFeature.state.selectDealerServiceMailLink);
    showMenu = this.store.selectSignal(DealerFeature.state.selectShowMenu);
    login = this.store.selectSignal(LoginFeature.state.selectIsAdmin); // TODO: Change to selectIsLoggedIn when edit mode is available to dealers

    toggleMenu() {
        this.store.dispatch(this.showMenu() ? DealerFeature.actions.hideMenu() : DealerFeature.actions.showMenu());
    }

    logout() {
        this.store.dispatch(LoginFeature.actions.logout());
    }
}
